import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const VideoWrapper = styled.div`
    box-shadow: 1px 1px 10px rgba(0,0,0,0.25);
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 180px;
`;

const PlayButton = styled.button`
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
`;

class VideoOptin extends Component {
    state = {
        showVideo: false,
    };

    componentDidMount() {
        const _this = this;
        const interval = setInterval(handleReactStuff, 100);

        function handleReactStuff() {
            if (!window.dataLayer) return;
            const showVideo = window.dataLayer[0]['YouTube Video'];
            _this.setVideoState(showVideo);

            clearInterval(interval);
        }
    }

    ShowPlayer = () => {
        const { showVideo } = this.state;
        const { src } = this.props;

        if (showVideo) {
            return (
                <iframe
                    title="Video"
                    src={src}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                />
            );
        }

        return (
            <PlayButton type="button" onClick={this.handleVideoClick}>Video abspielen</PlayButton>
        );
    };

    handleVideoClick = () => {
        this.setVideoState(true);
        window.UC_UI.acceptService('BJz7qNsdj-7').then(() => console.info('Service is accepted'));
    };

    setVideoState = (showVideo) => {
        this.setState({
            showVideo,
        });
    }

    /**
     *
     * @returns {JSX.Element}
     */
    render() {
        return (
            <VideoWrapper>
                <this.ShowPlayer />
            </VideoWrapper>
        );
    }
}

VideoOptin.propTypes = {
    src: PropTypes.string.isRequired,
};

export default VideoOptin;
