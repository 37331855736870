import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Highlight, P } from 'ui-kit/typography';

import Teaserbox from 'ui-kit/teaserbox';
import Intro from 'ui-kit/intro';
import Grid from 'ui-kit/grid';
import Section from 'ui-kit/section';
import { getImageFromNode } from 'helpers/utils';

import Container from 'ui-kit/container';
import VideoOptin from "ui-kit/video-optin";
import Layout from '../components/layout';
import Homestage from '../components/homestage';
import gridteaserRetailersearchImage from 'strohschwein-website/src/images/index-page/gridteaser-haendlersuche.svg';

/** styles teaserbox */
const StyledTeaserbox = styled.div`
    a {
        border: 3px solid white;
    }
`;

const IndexPage = ({ data }) => {
    const homestageBackgroundImage = data.homestageBackgroundImage.childImageSharp;
    const gridTeaserImageSmall1 = data.gridTeaserImageSmall1.childImageSharp;
    const gueteSiegel = data.gueteSiegel.childImageSharp;
    const { homestageTeaserImages } = data;

    /** Teaser-Cards für den Slider in der Startseite */
    const homestageCategoryTeasers = [
        <Teaserbox
            href="/haltung"
            key="/haltung"
            title="Haltung"
            image={
                getImageFromNode(homestageTeaserImages, 'index-page/kategorieteaser-haltung.jpg')
                    .node.childImageSharp
            }
            imageAlt="Schweine im Stall"
            centered
            noHover
            imageCritical
        />,
        <Teaserbox
            href="/herkunft-und-qualitaet"
            key="/herkunft-und-qualitaet"
            title="Herkunft & Qualität"
            image={
                getImageFromNode(homestageTeaserImages, 'index-page/kategorieteaser-herkunft.jpg')
                    .node.childImageSharp
            }
            imageAlt="Tracktor mit Stroh"
            centered
            noHover
            imageCritical
        />,
    ];

    return (
        <Layout description="Suedbayerische Fleischwaren">
            <StyledTeaserbox>
                <Homestage
                    intro={{
                        title: (
                            <>
                                <Highlight
                                    font="handwriting"
                                    className="homestage-serif"
                                    style={{ display: 'block' }}
                                >
                                    Neu im Sortiment:
                                </Highlight>
                                Das Edeka Premium Schwein
                            </>
                        ),
                        maxWidth: 1300,
                    }}
                    backgroundImage={homestageBackgroundImage}
                    teaserElements={homestageCategoryTeasers}
                />
            </StyledTeaserbox>
            <Section container="l">
                <Intro title="Jetzt entdecken und genießen!" gap="xxl">
                    <P fontSize="m">
                        Freuen Sie sich mit uns: Ab sofort können wir Ihnen exklusive Spezialitäten
                        vom Bayerischen Strohschwein anbieten. Lernen Sie hier das Besondere an der
                        Tierhaltung kennen - und einen vielversprechenden neuen Genuss gleich dazu.
                    </P>
                </Intro>
                <Grid>
                    <VideoOptin src="//www.youtube.com/embed/Sb4d2oItMUo" />
                    <Teaserbox
                        href="/nachhaltigkeit"
                        image={gridTeaserImageSmall1}
                        imageAlt="Vier unterschiedliche Auszubildene in selbstbewussten Posen"
                        layer={1}
                    />
                    <Teaserbox
                        href="/herkunft-und-qualitaet"
                        image={gridteaserRetailersearchImage}
                        imageAlt="Händlersuche Pins auf einer illustrierten Karte"
                        layer={1}
                    />
                </Grid>
            </Section>
            <Section bg="pattern">
                <Intro title="" gap="xl">
                    <P fontSize="m">
                        Das Bayerische Strohschwein ist kein Schwein wie jedes andere. Schon von
                        Geburt an geht es ihm besser als den meisten Artgenossen. Dafür sorgen die
                        ausgewählten bayerischen Landwirtschaftsbetriebe, die sich einem strengen,
                        staatlich überprüften Kontrollsystem unterziehen. Von der Herkunft über die
                        Aufzucht und Verarbeitung bis hin zu unserer Bedienungstheke können Sie den
                        hohen Anspruch ans Bayerische Strohschwein nachvollziehen. Die Regionalität
                        ist lückenlos nachweisbar, verbürgt vom Siegel „Geprüfte Qualität Bayern“,
                        wie auch vom QS-Zeichen für „Qualität und Sicherheit“.
                    </P>
                </Intro>
                <Container size="l" grid layout="5-2-5" cols="3" alignItems="center">
                    <div />
                    <Teaserbox
                        image={gueteSiegel}
                        imageAlt="Gütesiegel"
                        centered
                        noHover
                        imageCritical
                    />
                    <div />
                </Container>
            </Section>
        </Layout>
    );
};

/** Query für Page-Assets */
export const query = graphql`
    {
        homestageBackgroundImage: file(relativePath: { eq: "index-page/stage.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homestageTeaserImages: allFile(
            filter: { relativePath: { glob: "index-page/kategorieteaser-*.jpg" } }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 360, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        gridTeaserImageLarge: file(relativePath: { eq: "index-page/gridteaser-gqsiegel.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 815, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gridTeaserImageSmall1: file(
            relativePath: { eq: "index-page/gridteaser-strohschwein.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gueteSiegel: file(relativePath: { eq: "gq-siegel-schweinefleisch-2017.png" }) {
            childImageSharp {
                fluid(maxWidth: 200, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

IndexPage.propTypes = {
    data: PropTypes.shape({
        homestageBackgroundImage: PropTypes.object,
        gridTeaserImageSmall1: PropTypes.object,
        homestageTeaserImages: PropTypes.object,
        gueteSiegel: PropTypes.object,
    }).isRequired,
};

export default IndexPage;
