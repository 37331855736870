import React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import PropTypes, { node } from 'prop-types';

import { fluidImageType } from 'helpers/prop-types';
import { colors, fontSizes } from 'helpers/variables';
import { mq } from 'helpers/stylehelpers';

import Slider from 'ui-kit/slider';
import Stage from 'ui-kit/stage';
import Intro from 'ui-kit/intro';

/** Wrapper um die Komponente */
const Wrapper = styled.div`
    display: block;
    position: relative;
`;

/** Gestylte Bühne */
const StyledStage = styled(Stage)`
    ${mq.mediumDown`
        position: absolute;
        height: 70%;
        width: 100%;
        z-index: -1;

        > .gatsby-image-wrapper {
            height: 100%;
        }
    `};
`;

/** Wrapper um Intro & Slider herum */
const IntroAndSliderWrapper = styled.div`
    width: 100%;

    ${mq.large`
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-between;
        position: absolute;
        right: 0;
        top: 0;
    `};
`;

/** Intro */
const StyledIntro = styled(Intro)`
    color: ${colors.white};
    padding: ${rem(15)};
    width: 100%;

    ${mq.mediumDown`
        margin-bottom: 0.5em;
    `};

    ${mq.large`
        text-align: left;
        padding: ${rem(20)} ${rem(30)} ${rem(30)};
    `};

    ${mq.xxlarge`
        padding: ${rem(35)} ${rem(45)} ${rem(45)};
    `};

    ${mq.xxxlarge`
        padding: ${rem(50)} ${rem(60)} ${rem(60)};
    `};

    > h1 {
        line-height: 1;

        /* Serif stylen */
        strong.homestage-serif {
            font-size: ${rem(60)};

            ${mq.medium`
                font-size: ${rem(70)};
            `};

            ${mq.large`
                font-size: ${rem(70)};
            `};

            ${mq.xlarge`
                font-size: ${rem(90)};
            `};

            ${mq.xxlarge`
                display: block;
                line-height: 1;
                font-size: ${rem(100)};
            `};

            ${mq.xxxlarge`
                font-size: ${rem(110)};
            `};
        }
    }

    /* Buttons richtige größe geben */
    button,
    a {
        ${mq.xlarge`
            ${fontSizes.m};
        `};
    }
`;

/** Wrapping für Intro-Content */
const IntroContent = styled.div`
    ${fontSizes.s};
    text-shadow: 0px 0px ${rem(5)} ${rgba(colors.black, 0.25)};
    color: ${colors.black};

    ${mq.large`
        color: ${colors.white};
    `};

    ${mq.xlarge`
       ${fontSizes.m};
    `};
`;

/** Slider spezifisch gestyled */
const StyledSlider = styled(Slider)`
    display: block;

    ${mq.mediumDown`
        margin: 0 auto;
        max-width: ${rem(800)};
    `};

    ${mq.large`
        margin: 0;
        flex: 0 0 auto;
        width: 66.67% !important;
    `};
`;

const StyledTeaser = styled.div`
    display: flex;
    height: 70%;
    justify-content: center;

    ${mq.mediumDown`
        margin: 0 auto;
        max-width: ${rem(800)};
    `};

    ${mq.large`
        margin: 0;
        flex: 0 0 auto;
        justify-content: flex-end;
        padding-right: 0.625rem;
        width: 40.00% !important;
    `};

    a {
        flex: 0 0 50%;
        margin-right: 0.625rem;
        margin-left: 0.625rem;
    }
`;

/**
 * Generiert die Homestage
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {object} props.backgroundImage Das Hintergrundbild der Stage ((fluid) childImageSharp Object)
 * @param {array} props.sliderElements Elemente, die in den Slider sollen
 * @param {object} props.intro Daten für das Intro
 */
const Homestage = ({ className, backgroundImage, sliderElements, teaserElements, intro }) => {
    const { title, cta, content, maxWidth } = intro;
    return (
        <Wrapper className={className}>
            <StyledStage
                image={backgroundImage}
                imageAlt="Unscharfer Hintergrund der bayerische Landschaft zeigt"
            />
            <IntroAndSliderWrapper>
                <StyledIntro title={title} cta={cta} headlineLevel="h1" maxWidth={maxWidth}>
                    <IntroContent>{content}</IntroContent>
                </StyledIntro>
                {sliderElements && <StyledSlider hero>{sliderElements}</StyledSlider>}
                {teaserElements && <StyledTeaser hero>{teaserElements}</StyledTeaser>}
            </IntroAndSliderWrapper>
        </Wrapper>
    );
};

Homestage.propTypes = {
    className: PropTypes.string,
    backgroundImage: fluidImageType.isRequired,
    sliderElements: PropTypes.arrayOf(node),
    teaserElements: PropTypes.arrayOf(node),
    intro: PropTypes.shape({
        title: PropTypes.node.isRequired,
        cta: PropTypes.node,
        children: PropTypes.node,
        content: PropTypes.node,
        maxWidth: PropTypes.number,
    }).isRequired,
};

Homestage.defaultProps = {
    className: null,
    sliderElements: null,
    teaserElements: null,
};

export default Homestage;
